.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  margin-left: 80px;
  margin-right: 80px;
}

@media (max-width: 768px) {
  .services-list :nth-child(2),
  .services-list :nth-child(4) {
    flex-direction: column-reverse;
    font-size: 20px;
  }
}

.landingpage {
  background-image: linear-gradient(
      180deg,
      rgba(0, 113, 188, 0.9) 0.03%,
      rgba(0, 113, 188, 0.9) 70.82%,
      rgba(0, 58, 97, 0.9) 99.45%
    ),
    url("https://cdn.mednebula.com/static/landing/Bd6lTTgI6YRDIwdapUofLD0QMelQjyDnpjcK8WXayT1pUyUySWv6JGxjo2d0fV4k/ol62C6mfnPsdOay5.jpg");
}
